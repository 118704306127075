import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { DsComponentsAttachmentsModule } from '@design-system/components/attachments';
import { DsBreadcrumbsModule } from '@design-system/components/breadcrumbs';
import { DS_APP_WRAPPER_CONFIG } from '@design-system/feature/app-wrapper-v2';
import { DsPageModule } from '@design-system/feature/page';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { APPLICATION_INSIGHTS_KEY } from '@shared-lib/app-insights';
import { UnsavedDataGuard } from '@utils/unsaved-data-guard';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { ClaimAttachmentsComponent } from './claims/claim-details/claim-attachments/claim-attachments.component';
import { ClaimDetailsComponent } from './claims/claim-details/claim-details.component';
import { ClaimOverviewComponent } from './claims/claim-details/claim-overview/claim-overview.component';
import { PositionValidPipe } from './claims/claim-details/claim-positions/claim-positions-valid.pipe';
import { ClaimPositionsComponent } from './claims/claim-details/claim-positions/claim-positions.component';
import { ClaimListComponent } from './claims/claim-list/claim-list.component';
import { CoreModule } from './core.module';
import { SharedModule } from './shared/shared.module';
import { TotalClaimPipe } from './shared/total-claim.pipe';
import {
  AppWrapperModule,
  HEALTH_CHECK_OPTIONS_TOKEN,
  HealthCheckBannerOptions,
} from '@design-system/feature/app-wrapper-v2';

@NgModule({
  declarations: [
    AppComponent,
    ClaimListComponent,
    ClaimDetailsComponent,
    ClaimPositionsComponent,
    ClaimOverviewComponent,
    PositionValidPipe,
    TotalClaimPipe,
    ClaimAttachmentsComponent,
  ],
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    SharedLibFeatTranslationModule,
    DsPageModule,
    CoreModule,
    AppWrapperModule.forRoot([
      {
        provide: DS_APP_WRAPPER_CONFIG,
        useFactory: () => ({
          apiBasePath: environment.palfinger_api_management,
          paldeskBasePath: environment.LINKS.PALDESK_BASEPATH,
          appInsightsRole: 'FE: CLAIM_ON_SUPPLIERS',
          appGatewayBasePath: environment.palfinger_app_gateway,
          appHelpPath: 'help',
          appName: 'Claim on suppliers',
          paldeskSupportPath: environment.LINKS.STATUS_PALDESK_PAGE,
          productScreenBasePath: environment.LINKS.PRODUCT_SCREEN,
        }),
      },
      {
        provide: APPLICATION_INSIGHTS_KEY,
        useFactory: () => environment.application_insights_key,
      },
    ]),
    DsBreadcrumbsModule,
    DsComponentsAttachmentsModule,
  ],
  providers: [
    UnsavedDataGuard,
    // HEALTHCHECK STATUS BANNER
    {
      provide: HEALTH_CHECK_OPTIONS_TOKEN,
      useFactory: (): HealthCheckBannerOptions => ({
        productsToCheck: ['Claim-On-Suppliers'],
        statusPaldeskApiUri:
          environment.palfinger_app_gateway + '/status/api/v1/health',
        statusPaldeskPageUri: environment.LINKS.STATUS_PALDESK_PAGE,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
